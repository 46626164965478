export const cap = (str) => {
    return str
        .toLowerCase()
        .split(' ')
        .map(function(word) {
            return word[0].toUpperCase() + word.substr(1);
        })
        .join(' ')
}

export const gambar = (url, q, gravity) => {
    url = 'https://ameljbgvqq.cloudimg.io/v7/' + url + `?force_format=webp&q=${q?q:50}`
    if (gravity) {
        url = url + `&gravity=${gravity}`
    }
    return url
}

export const pw = (repo, name) => {
    return ('https://my.possiblewedding.com/wp-content/uploads/git/' + repo + '/' + name)
}
import React, { Component } from 'react'
import { Helm } from '../components/header'
import Container from 'react-bootstrap/Container'
import { Form, Row, Col, Alert, Button, InputGroup, FormControl, Spinner, Input } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import getData from '../params/getdata'
import { faArrowRight, faArrowDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import '../style/guest.css'
import '../style/table.scss'
import MediaQuery from 'react-responsive'
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { pw } from '../params';
library.add(faArrowRight, faArrowDown, faSearch);
export default class Halo extends Component {
    constructor(props) {
        super(props);
        this.nama = React.createRef()
        this.state = {
            show: {

            }
        }
    }
    async componentDidMount() {
        try {
        } catch (e) {
            console.log(e)
        }
    }
    convert = (data) => {
        console.log(data)
    }

    render() {
        let { show } = this.state

        return (
            <>
                <Form>

                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Label>Data Input</Form.Label>
                        <Form.Control as="textarea" rows={3} onChange={(e) => {
                            e.preventDefault()
                            let { value } = e.currentTarget
                            let data = value.split('\n')
                            let data1 = {}
                            data.map(v => {
                                let s= v.split(': ')
                                
                                if(v.includes('Nama Lengkap Pria')){
                                    
                                    data1.lengkap_co=s[1]
                                }else if(v.includes('Nama Lengkap Wanita')){
                                    data1.lengkap_ce=s[1]
                                }
                                else if(v.includes('Nama Panggilan Pria')){
                                    data1.inisial_co=s[1]
                                }
                                else if(v.includes('Nama Panggilan Wanita')){
                                    data1.inisial_ce=s[1]
                                }
                                else if(v.includes('Nama Ayah Mempelai Pria')){
                                    data1.bapak_co =s[1]
                                }
                                else if(v.includes('Nama Ibu Mempelai Pria')){
                                    data1.ibu_co=s[1]
                                }
                                else if(v.includes('Nama Ayah Mempelai Wanita')){
                                    data1.bapak_ce=s[1]
                                }
                                else if(v.includes('Nama Ibu Mempelai Wanita')){
                                    data1.ibu_ce=s[1]
                                }
                                else if(v.includes('Instagram Mempelai Pria')){
                                    data1.ig_co=s[1]
                                }
                                else if(v.includes('Instagram Mempelai Wanita')){
                                    data1.ig_ce=s[1]
                                }
                            })
                            this.setState({show:data1})
                        }} />
                    </Form.Group>
                </Form>
                {
                    show.bapak_ce?(
                        <code>
                    let id = '{show.inisial_ce.toLowerCase()}-{show.inisial_co.toLowerCase()}'<br />
                    let inisial_co = '{show.inisial_co}'<br />
                    let inisial_ce = '{show.inisial_ce}'<br />
                    let lengkap_co = '{show.lengkap_co}'<br />
                    let lengkap_ce = '{show.lengkap_ce}'<br />
                    let bapak_co = 'Bpk. {show.bapak_co}'<br />
                    let ibu_co = 'Ibu {show.ibu_co}'<br />
                    let bapak_ce = "Bpk. {show.bapak_ce} "<br />
                    let ibu_ce = "Ibu {show.ibu_ce}"<br />
                    let ig_co = "{show.ig_co}"<br />
                    let ig_ce = "{show.ig_ce}"<br />
                </code>
                    ):false
                }
            </>

        )
    }
}